/**
 *
 * @returns {*|AxiosPromise}
 * @param data
 */
export function createUserTariff(data) {
    return axios({
        url: `/api/tariff-settings/create`,
        method: 'post',
        data: data,
    });
}

/**
 *
 * @param data
 * @returns {*}
 */
export function updateUserTariff(data) {
    return axios({
        url: `/api/tariff-settings/update`,
        method: 'put',
        data: data,
    });
}

/**
 *
 * @param data
 * @returns {*}
 */
export function updateUserDgoTariff(data) {
    return axios({
        url: `/api/tariff-dgo-settings/update`,
        method: 'put',
        data: data,
    });
}


/**
 *
 * @param data
 * @returns {*|AxiosPromise}
 */
export function removeTariffs(ids) {
    return axios({
        url: `/api/tariff-settings/remove`,
        method: 'delete',
        data: {ids: ids},
    });
}
